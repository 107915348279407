.headerEmpresa {
  display: flex;
  align-items: center;
}

.selectCliente {
  display: flex;
  align-items: center;
  width: 25%;

  overflow: hidden;
  white-space: nowrap;
}

.selectCliente label {
  margin: 0;
  font-weight: bold;
}

.headerEmpresa .ant-btn {
  max-width: fit-content;
  width: 10%;

  overflow: hidden;
  white-space: nowrap;
}

.ant-table-footer {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0;
}

.ant-table-footer::before {
  background-color: white;
}

.footerContainer {
  text-align: center;
}
