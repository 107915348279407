@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@media only screen and (max-width: 600px) {
    body {
        width: 100% !important;
    }

    .divTable {
        width: 100% !important;
    }
}

.ant-table {
    
    display: block;

    @media screen and (max-width: 600px) {
        &-thead {
            display: none !important;
        }

        &-thead>tr,
        &-tbody>tr {

            th,
            td {
                &:first-of-type {
                    padding-top: 1rem !important;
                }

                &:last-of-type {
                    padding-bottom: 1rem !important;
                }
            }

            >th,
            >td {
                display: block !important;
                width: auto !important;
                border: none !important;
                padding: 0 1rem !important;
                font-size: 1.1rem !important;

                &:last-child {
                    border-bottom: 1px solid #eee !important;
                }
            }
        }
    }
}


.icon {
    position: relative;
    top: -3px;
}

.btnGerente {
    color: white !important;
    background-color: rgb(96, 177, 76) !important;
}

.btnVendedor {
    color: white !important;
    background-color: rgb(57, 131, 201) !important;
}
body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.ant-layout-has-sider {
    max-height: 93vh !important;
}

.ant-layout-sider {
    overflow-y: scroll !important;
    max-height: 93vh;
}


.logoLayoutMaster {
  position: relative;
  top: -30px;
  left: -10px;
  width: 15.6rem;
  height: 7rem;
  margin: 1rem;
  content: url("https://storage.googleapis.com/bluve-bucket/logo_oficial_branca_com_azul-min.png");
}


.logo {
    margin: 0.625rem;
    height: 5rem;
    min-width: 10%;
    color: white;
    font-family: 'Trochi', serif;
    font-size: 2.5vw;
    float: left;
}


.logoMoub {
    position: relative;
    text-align: center;
    padding: 0px;
    width: 122px;
    margin-left: 0px;
    top: -10px;
    left: -7px;
    content: url("https://storage.googleapis.com/moub-bucket/moub-logo.png");
}

.center {
    margin: auto;
    color: white;
    justify-content: center;
    font-family: 'Trocchi', serif;
    font-size: 2.5vw;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.center img {
    position: relative;
    text-align: center;
    padding: 0.313rem;
    width: 3.125rem;
    margin-left: 0.313rem;
}

.labelLogoShow {
    position: relative;
    top: 0.313rem;
    width: 100%;
    font-size: 3vw;
    text-align: center;
}

table {
    margin-bottom: 3.125rem;
}

.card-profile {
    position: relative;
    top: -28px !important;
    left: -25px !important;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    margin: auto;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    width: 250px;
    background-color: #e6e6e6;
}

.card-profile > label {
    font-size: 0.75rem;
    display: block;
    margin-bottom: 0;
    margin-right: 0.313rem;
    text-align: end;
}

.pspan {
    position: relative;
    margin-left: 0.313rem;
    margin-right: 0.313rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.img-profile {
    width: 120px !important;
    border-radius: 50%;
    position: relative;
    top: 10px;
    margin-bottom: 15px;
}

.title-profile {
    color: rgb(87, 86, 86);
    font-size: 1.125rem;
    position: relative;
    top: 5px;
}

.buttonProfile {
    border: none;
    outline: 0;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    width: 80%;
    top: -0.75rem;
}

.buttonProfileExit {
    border: none;
    outline: 0;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    width: 80%;
    top: -1rem;
}


.a-profile {
    text-decoration: none;
    font-size: 1.375rem;
    color: black;
}

.buttonProfile:hover,
.a-profile:hover {
    opacity: 0.7;
}

.iconPageLayout {
    position: relative;
    top: -0.313rem;
}

.profileAvatar {
    border: 0.125rem solid #D2D2D2;
    cursor: pointer;
}

.chartTitle {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.divDashSpin {
    position: absolute;
    width: 100%;
    left: 0px;
    float: left;
    display: grid;
    top: 120px;
}

.card-box {
    position: relative;
    color: #fff;
    padding: 20px 10px 40px;
    margin: 20px 0px;
    max-height: 125px !important;
}

.card-box:hover {
    text-decoration: none;
    color: #f1f1f1;
}

.card-box:hover .icon i {
    font-size: 100px;
    transition: 1s;
    -webkit-transition: 1s;
}

.card-box .inner {
    padding: 5px 10px 0 10px;
}

.card-box h3 {
    font-size: 27px;
    font-weight: bold;
    margin: 0 0 8px 0;
    white-space: nowrap;
    padding: 0;
    text-align: left;
    color: wheat;
}

.card-box p {
    font-size: 15px;
}

.card-box .icon {
    position: absolute;
    top: auto;
    bottom: 5px;
    right: 5px;
    z-index: 0;
    font-size: 72px;
    color: rgba(0, 0, 0, 0.15);
}

.card-box .card-box-footer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    text-align: center;
    padding: 3px 0;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    text-decoration: none;
}

.card-box:hover .card-box-footer {
    background: rgba(0, 0, 0, 0.3);
}

.bg-blue {
    background-color: #00c0ef !important;
}

.bg-green {
    background-color: #00a65a !important;
}

.bg-orange {
    background-color: #ebb134 !important;
}

.bg-red {
    background-color: #d9534f !important;
}

.bg-purple {
    background-color: #a234eb !important;
}
.atendimentos-card-realizados {
    background-color: #1890FF;
}

.atendimentos-card-vendas-perdidas {
    background-color: #ffe2bf;
}

.atendimentos-card-taxa-convesao {
    background-color: #bfeeff;
}


.atendimentos-card-media-atendimento {
    background-color: #e47d00;
}


.atendimentos-card {
    margin-top: 14px;
    margin-bottom: 14px;
}


.help-button-container {
    position: relative;
    text-align: right;
}

.help-button {
    left: -10px;
    right: 10px;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    color: #0078E4;
    font-size: 18px;
 }

 .title-container {
    position: relative;
    top: -30px;
    font-size: 18px;
 }
.bodyLogin {
  background-color: grey;
  background-size: cover;
  background-position: center center;
  position: absolute;
  width: 100% !important;
  height: 100%;

}


@media only screen and (max-width: 600px) {
  .centerLoginAdmin {
    width: 80% !important;
    height: 45% !important;
  }
}

.centerLoginAdmin {
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 30%;
  height: 70%;
  text-align: center;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}



.titleLoginH1Admin {
  font-size: 36px;
  font-family: 'Poppins-Bold';
  color: white;
}

.titleLoginH6Admin {
  font-size: 18px;
  font-family: 'Poppins-Bold';
  position: relative;
  top: -20px;
  color: white;
}

.login-form-admin {
  max-width: 300px;
  position: relative;
  top: 30%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.login-form-forgot-admin {
  float: right;
}

.login-form-button-admin {
  width: 100%;
}

.EsqueciSenhaAdmin {
  float: right;
  color: white !important;
  text-decoration: underline !important;
}

.bodyLogin .ant-form-explain {
  color: yellow !important;
}

.lblHeaderConfig {
    position: fixed !important;
    left: 240px !important;
    font-size: 18px;
}

.div-form-profile {
    position: relative;
    width: 800px;
    margin-top: 50px;
}

.div-form-profile label {
    position: relative;
    left: -50px;
    width: 140px !important;
}

.div-form-profile input {
    position: relative;
    left: -40px;
    width: 546px;
}

.uploadComponent {
    display: none !important;
}
.premiacaoValor {
    border: solid 1px;
    border-radius: 4px !important;
    width: 220px;
    padding-left: 5px;
    border-color: rgb(218, 215, 215);
    height: 33px;
    width: 120px;
}

.btnLoyalty {
    width: 1px;
    height: 25px;
}

.btnEditLoyalty {
    color: white !important;
    background-color: #EC9720 !important;
}

.btnDeleteLoyalty {
    color: white !important;
    background-color: #ec5020 !important;
}

.iconLoyalty {
    position: relative !important;
    left: -7px;
    top: -2px
}

.divPremiacaoValor {
    margin-bottom: 20px;
}

.divPontosParaTroca {
    margin-bottom: 25px;    
}

.iconCodigoProdutoHelp {
    position: relative;
    margin-left: 5px;
    top: -2px
}

.tableSetor {
    color: #ec5020;
    position: relative;
    top: 30px;
    left: -0px;
    width: 450px;
}
.headerEmpresa {
  display: flex;
  align-items: center;
}

.selectCliente {
  display: flex;
  align-items: center;
  width: 25%;

  overflow: hidden;
  white-space: nowrap;
}

.selectCliente label {
  margin: 0;
  font-weight: bold;
}

.headerEmpresa .ant-btn {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 10%;

  overflow: hidden;
  white-space: nowrap;
}

.ant-table-footer {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0;
}

.ant-table-footer::before {
  background-color: white;
}

.footerContainer {
  text-align: center;
}

.headerGerente {
  display: flex;
  align-items: center;
}

.selectCliente {
  display: flex;
  align-items: center;
  width: 25%;

  overflow: hidden;
  white-space: nowrap;
}

.selectCliente label {
  margin: 0;
  font-weight: bold;
}

.headerGerente .ant-btn {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 10%;

  overflow: hidden;
  white-space: nowrap;
}

.ant-table-footer {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0;
}

.ant-table-footer::before {
  background-color: white;
}

.footerContainer {
  text-align: center;
}

.excel-button-v2 {
  position: relative;
  background-color: #00b050;
  color: #fff;
  border: none;
  box-shadow: none;
}

.avatarVendedorImgBckground {
    position: relative;
    left: -24px !important;
    width: 520px !important;
    top: -10px !important;
    height: 70px !important;
    background-color: transparent;
    text-align: center;
    display: list-item;
}

.avatarVendedorImg {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border-style: solid;
    border-color: #FFFFFF;
    box-shadow: 0 0 8px 3px #B8B8B8;
    position: relative;
    object-fit: cover;
    cursor: pointer;
}

.avatarVendedorIcon {
    cursor: pointer;
    position: relative;
    top: -60px;
    left: 285px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: cornflowerblue;
    box-shadow: 0 0 8px 3px #B8B8B8;
}
.headerVendedor {
  display: flex;
  align-items: center;
}

.selectContainer {
  display: flex;
  align-items: center;
  width: 25%;

  overflow: hidden;
  white-space: nowrap;
}

#selectEmpresa {
  margin-left: 0.625rem;
}

.selectContainer label {
  margin: 0;
  font-weight: bold;
}

.headerVendedor .ant-btn {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 10%;

  overflow: hidden;
  white-space: nowrap;
}

.ant-table-footer {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0;
}

.ant-table-footer::before {
  background-color: white;
}

.footerContainer {
  text-align: center;
}

