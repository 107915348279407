.headerGerente {
  display: flex;
  align-items: center;
}

.selectCliente {
  display: flex;
  align-items: center;
  width: 25%;

  overflow: hidden;
  white-space: nowrap;
}

.selectCliente label {
  margin: 0;
  font-weight: bold;
}

.headerGerente .ant-btn {
  max-width: fit-content;
  width: 10%;

  overflow: hidden;
  white-space: nowrap;
}

.ant-table-footer {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0;
}

.ant-table-footer::before {
  background-color: white;
}

.footerContainer {
  text-align: center;
}

.excel-button-v2 {
  position: relative;
  background-color: #00b050;
  color: #fff;
  border: none;
  box-shadow: none;
}
