.atendimentos-card-realizados {
    background-color: #1890FF;
}

.atendimentos-card-vendas-perdidas {
    background-color: #ffe2bf;
}

.atendimentos-card-taxa-convesao {
    background-color: #bfeeff;
}


.atendimentos-card-media-atendimento {
    background-color: #e47d00;
}


.atendimentos-card {
    margin-top: 14px;
    margin-bottom: 14px;
}


.help-button-container {
    position: relative;
    text-align: right;
}

.help-button {
    left: -10px;
    right: 10px;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    color: #0078E4;
    font-size: 18px;
 }

 .title-container {
    position: relative;
    top: -30px;
    font-size: 18px;
 }